import React from "react";
import { MdOutlineClose } from "react-icons/md";
import MyAfrimalllogo from "../Assets/MyAfrimalllogo.png";

const Sidebar = ({ isSidebarOpen, closeSidebar, navItems }) => {
  return (
    <aside
      className={`${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      } fixed w-full h-screen bg-white top-0 right-0 transition p-8 md:hidden z-20`}
    >
      <div className='flex justify-between items-center'>
        <img
          onClick={() => {
            closeSidebar();
          }}
          src={MyAfrimalllogo}
          className='w-24'
          alt=''
        />
        <i onClick={closeSidebar} className='text-2xl flex justify-end'>
          <MdOutlineClose className='' />
        </i>
      </div>
      <div className='flex flex-col my-10'>
        {navItems.map(({ name, url }, index) => {
          return (
            <a
              key={index}
              href={url}
              className='text-black whitespace-nowrap text-lg capitalize border-b-2 py-6 font-medium'
              onClick={closeSidebar}
            >
              {name}
            </a>
          );
        })}
      </div>
      <img
        src={MyAfrimalllogo}
        className='absolute bottom-0 right-0 opacity-10'
        alt=''
      />
    </aside>
  );
};

export default Sidebar;
