import React from "react";
import Frame from "../Assets/Frame.jpg";
import round from "../Assets/round.png";
import Phone from "../Assets/Pix.svg";
import "../../../App.css";

const Download = () => {
  return (
    <div
      className="w-full bg-cover bg-center h-[35rem] flex lg:space-x-36 md:space-x-0 sm:space-x-0 "
      style={{ backgroundImage: `url(${Frame})` }}>
      <div
        className="flex flex-col mx-20 md:mr-6 ml-[1rem]"
        data-aos="fade-left">
        <p className="uppercase text-5xl font-black py-4 text sm:ml-10">
          Have you downloaded <br /> our Mobile app?
        </p>
        <ol className="list-decimal leading-10 ml-4 sm:ml-16 ">
          <li className="pl-2">To download our mobile app, go to</li>
          <li className="pl-2">Enter your info on the sign-up page</li>
          <li className="pl-2">Verify your account</li>
          <li className="pl-2">Log in to your account</li>
          <li className="pl-2">
            Send, receive, and track shipments from your phone
          </li>
        </ol>
      </div>

      <div
        className="relative mt-20 md:hidden lg:block md:pl-[5rem]"
        data-aos="fade-right">
        <div className="relative hidden lg:block">
          <img
            src={round}
            alt=""
            className="w-[23rem] bg-transparent opacity-85"
          />
          <div className="w-[16rem] h-[16rem] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-500 rounded-full opacity-30 md:bottom-10"></div>
        </div>

        <img
          src={Phone}
          alt=""
          className=" md:w-52 md:relative md:bottom-[25rem]   md:left-24 sm:hidden md:block"
        />
      </div>
    </div>
  );
};

export default Download;
