import React from "react";
import MyAfrimallfooter from "../Assets/MyAfrimallfooter.png";
import instagram from "../Assets/instagram.png";
import facebook from "../Assets/facebook.png";
import linkedin from "../Assets/linkedin.png";
import tk from "../Assets/tk.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black px-12 py-12 grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="text-sm md:text-left space-y-4">
        <div>
          <img src={MyAfrimallfooter} alt="logo" />
        </div>
        <div className="text-white">
          <p className="mt-6 leading-relaxed ">
            myafrimall logistics is an independent customs broker/customs <br />
            intermediary dedicated to providing solutions to all customs <br />
            clearance needs whether import or export.
          </p>
        </div>
      </div>
      {/* links */}
      <div className="text-white flex flex-row gap-6 w-full justify-between">
        <div className="grid text-sm  ">
          <h1 className="text-[#049805] font-bold ">Quick Links</h1>
          <a href="home" className="">
            Our Services
          </a>
          <a href="home">Visit Our Store</a>
          <a href="contact" id="contact">
            Contact us
          </a>
        </div>

        <div className="flex flex-col space-y-6 md:space-y-1 text-sm leading-7 ">
          <h1 className="text-[#049805] font-bold">Important Links</h1>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Term and Condition</Link>

          <div className="flex gap-3 mt-2">
            <a
              href="https://web.facebook.com/myafrimallofficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a href="/#">
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              href="https://www.tiktok.com/search?q=myafrimall&t=1697198981229"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tk} alt="twitter" />
            </a>
            <a
              href="https://www.instagram.com/myafrimall/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
