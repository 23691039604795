import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "react-whatsapp-widget/dist/index.css";
import HomeLayout from "./pages/LandingPage/Components/HomeLayout";
import Home from "./pages/LandingPage/Components/Home";
import Terms from "./pages/LandingPage/Components/Terms";
import PrivacyPolicy from "./pages/LandingPage/Components/PrivacyPolicy";
import ContactUs from "./pages/LandingPage/Components/ContactUs";
import Scroll from "./utils/Scroll";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
    ],
  },
  {
    path: "contact",
    element: <ContactUs />,
  },
  {
    path: "terms",
    element: <Terms />,
  },
  {
    path: "privacy",
    element: <PrivacyPolicy />,
  },
]);

const App = () => {
  return (
    <Scroll>
      <RouterProvider router={router}></RouterProvider>
    </Scroll>
  );
};

export default App;
