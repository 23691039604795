import React from "react";
import Electronics from "../Assets/Shippings/Electronis.jpg";
import FoodItems from "../Assets/Shippings/Foods.jpg";
import Materials from "../Assets/Shippings/Materials.png";
import Jewelries from "../Assets/Shippings/Jewelries.png";
import Shoes from "../Assets/Shippings/Shoes.png";
import Furnitures from "../Assets/Shippings/Furnitures.jpg";
import Fabrics from "../Assets/Shippings/Fabrics.jpg";
import Meds from "../Assets/Shippings/Pharmaceuticals.png";
import Aero from "../Assets/Shippings/Aero.jpg";
import Agric from "../Assets/Shippings/Agric.jpg";
import Arts from "../Assets/Shippings/Arts.jpg";
import Autos from "../Assets/Shippings/Autos.jpg";
import Books from "../Assets/Shippings/Books.jpg";
import Chemicals from "../Assets/Shippings/Chemicals.jpg";
import Docs from "../Assets/Shippings/Doc.jpg";
import Electricals from "../Assets/Shippings/Elect.jpg";
import Fragile from "../Assets/Shippings/Fragile.jpg";
import Hazards from "../Assets/Shippings/Hazard.jpg";
import hival from "../Assets/Shippings/hi-val.jpg";
import Industrials from "../Assets/Shippings/Industrial.jpg";
import Livestock from "../Assets/Shippings/Livestock.jpg";
import Perishables from "../Assets/Shippings/Perishables.jpg";
import Plants from "../Assets/Shippings/Plants.jpg";
import Toys from "../Assets/Shippings/Toys.jpg";
import "../../../App.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const WhatWeShip = () => {
  const Shippings = [
    {
      id: 1,
      img: FoodItems,
      tag: "Food Items",
    },
    {
      id: 2,
      img: Shoes,
      tag: "shoes",
    },
    {
      id: 3,
      img: Jewelries,
      tag: "Jewelries",
    },
    {
      id: 4,
      img: Furnitures,
      tag: "Furniture",
    },
    {
      id: 5,
      img: Materials,
      tag: "Materials",
    },
    {
      id: 6,
      img: Fabrics,
      tag: "Fabrics",
    },
    {
      id: 7,
      img: Electronics,
      tag: "Electronics",
    },
    {
      id: 8,
      img: Meds,
      tag: "Pharmaceuticals",
    },
    {
      id: 9,
      img: Aero,
      tag: `Aerospace/${"\n"}Aviation Items`,
    },
    {
      id: 10,
      img: Toys,
      tag: "Children Toys/Games",
    },
    {
      id: 11,
      img: Docs,
      tag: "Legal Documents",
    },
    {
      id: 12,
      img: Electricals,
      tag: `Electrical/${"\n"}Mechanical Parts`,
    },
    {
      id: 13,
      img: hival,
      tag: "High-value Items",
    },
    {
      id: 14,
      img: Hazards,
      tag: "Hazardous Materials",
    },
    {
      id: 15,
      img: Perishables,
      tag: "Perishables",
    },
    {
      id: 16,
      img: Fragile,
      tag: "Fragile Item",
    },
    {
      id: 17,
      img: Industrials,
      tag: "Industrial Equipments",
    },
    {
      id: 18,
      img: Autos,
      tag: "Automobiles",
    },
    {
      id: 19,
      img: Books,
      tag: "Books",
    },
    {
      id: 20,
      img: Agric,
      tag: "Agricultural Products",
    },
    {
      id: 21,
      img: Plants,
      tag: "Plants",
    },
    {
      id: 22,
      img: Livestock,
      tag: "Livestock",
    },
    {
      id: 23,
      img: Arts,
      tag: "Arts and Artifacts",
    },
    {
      id: 24,
      img: Chemicals,
      tag: "Industrial Chemicals",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1400,
    slidesToShow: 8,
    slidesToScroll: 8, 
    autoplay: true,

    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4, 
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8, 
        },
      },
    ],
  };

  return (
    <div class="flex flex-col h-72">
      <p
        class=" font-extrabold flex justify-center text mb-4 text-4xl md:text-7xl"
        data-aos="fade-up">
        WHAT WE SHIP
      </p>
      <Slider {...settings} className="flex flex-row">
        {Shippings.map((items) => (
          <div key={items.id} className="flex flex-col items-center">
            <img
              src={items.img}
              alt=""
              className="md:w-40 md:h-28 h-16 w-26 p-[2px] md:p-0 sm:w-16 sm:h-16 sm:space-x-8 rounded flex mx-auto "
            />
            <p className="text-center text-sm  sm:text-[8px] md:text-lg">{items.tag}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default WhatWeShip;
