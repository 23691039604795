import React from "react";
import "../../../App.css";
import RedIcon from "../Assets/SVGs/red.svg";
import "../../../App.css";

const Services = () => {
  return (
    <div className="flex flex-col ">
      <header className="uppercase text-center text font-black text-5xl py-14">
        Our Services
      </header>

      <div className="flex flex-col md:flex-row justify-center w-full xl:space-x-32 md:space-x-4 sm:space-x-0 tracking-widest">
        <div
          className="bg-gray-50 w-full md:w-[32rem] h-[15rem] md:h-[18rem] p-4 shad mb-4 md:mb-0"
          data-aos="fade-left">
          <span className="flex space-x-4 p-3 py-2">
            <img src={RedIcon} alt="" className="bg-blue-600 rounded w-10" />
            <p className="font-bold">Import</p>
          </span>
          <p className="p-3 w-full md:w-full lg:w-1/2 xl:w-full">
            Import from USA or China and have them <br /> delivered to you
            within 3-5 working days, 7-10 business days or in 3 months depending
            on the shipping method you would like to use.
          </p>
        </div>

        <div
          className="bg-gray-50 w-full md:w-[32rem] h-[15rem] md:h-[18rem] sm:ml-4 p-4 shad1"
          data-aos="fade-right">
          <span className="flex space-x-4 p-3 py-3">
            <img src={RedIcon} alt="" className="bg-red-600 rounded w-10" />
            <p className="font-bold">Export</p>
          </span>
          <p className="p-3 w-full md:w-full lg:w-1/2 xl:w-full">
            Ship items from Nigeria to any destination in China or the USA, and
            the recipient can expect delivery in 3-5 working days, 10-15 working
            days, <br /> 21 days, or 3 months, depending on the chosen shipping
            method.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
