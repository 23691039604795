import React from "react";

// Imported dependencies
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../../App.css";
// Imported Images
import Olusola from "../Assets/Customers/Woman1.jpg";
import Lopes from "../Assets/Customers/Man4.jpg";
import Heritage from "../Assets/Customers/man5.jpg";
import Tracy from "../Assets/Customers/Woman2.jpg";
import Adewale from "../Assets/Customers/Man2.jpg";
import Adeem from "../Assets/Customers/Man3.jpg";

// Imported BGs
import Blue from "../Assets/TestimonialBgs/BlueBg.jpg";
import Lemon from "../Assets/TestimonialBgs/LemonBg.jpg";
import Pink from "../Assets/TestimonialBgs/PinkBg.jpg";

const Testimonials = () => {
  const Customers = [
    {
      id: 1,
      name: "Olusola",
      img: Olusola,
      description:
        "Being an affiliate for MyAfrimall Logistics has been a rewarding journey. Their efficient logistics services and a well-structured affiliate program have  not only provided a steady income stream but also allowed me to  confidently recommend a trusted  partner in the industry.",
    },
    {
      id: 2,
      name: "Lopes",
      img: Lopes,
      description:
        "Affiliating with MyAfrimall Logistics has not only provided me with consistent financial gains but also a sense of pride in endorsing a reliable and reputable brand. The user-friendly platform, on-time deliveries, and attentive customer support have generated positive feedback, contributing to increased conversions and customer satisfaction.",
    },
    {
      id: 3,
      name: "Heritage",
      img: Heritage,
      description:
        "Becoming an affiliate for MyAfrimall Logistics has proven to be a wise  decision for both my audience and me. The seamless logistics solutions they provide, coupled with a lucrative affiliate program, have not only boosted my earnings but also enriched my credibility by aligning with a company known for reliability and excellence in the logistics industry.",
    },
    {
      id: 4,
      name: "Tracy",
      img: Tracy,
      description:
        "Being an affiliate for MyAfrimall Logistics has been a game-changer for me. Their top-notch services and  generous affiliate program have not only boosted my income but also made it a pleasure to endorse a logistics partner that consistently exceeds expectations.",
    },
    {
      id: 5,
      name: "Adewale",
      img: Adewale,
      description:
        "As a proud affiliate of MyAfrimall  Logistics, I've found a winning  combination of financial success and  customer satisfaction. Their commitment to seamless logistics  and a generous affiliate program has not only bolstered my income but also allowed me to confidently endorse a trustworthy and dependable partner in the shipping and logistics realm.",
    },
    {
      id: 6,
      name: "Adeem",
      img: Adeem,
      description:
        "Partnering with MyAfrimall Logistics as an affiliate has been a lucrative and seamless experience. Their reliable  services and transparent affiliate program have not only increased my earnings but also reinforced my confidence in promoting a brand that  truly delivers on its promises.",
    },
  ];

  const settings = {
    dots: true, 
    infinite: true,
    speed: 1400,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true, 
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <div className="">
      <div>
        <header className="uppercase text-center text font-bold text-4xl py-14  ">
          What are people saying
        </header>
      </div>
      <Slider {...settings} className="mb-14">
        {Customers.map((customer, index) => {
          let boxShadow, backgroundColor, transform;

          switch (index) {
            case 0:
            //   boxShadow =
            //     "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.1)";
              backgroundColor = "#FFFAFA";
              break;

            case 1:
              //    boxShadow = "0 5px 9px 50px rgba(25, 334, 330, 0.8)";
              //    transform = "rotate(45deg)";
              backgroundColor = "#FD23";
              break;

            case 2:
            //   boxShadow = "23px 8px 16px -8px rgba(12, 40, 59, 0.8)";
              backgroundColor = "6B8E23";
              break;

            case 3:
            //   boxShadow = "5px 5px 10px 0px rgba(0, 0, 0, 0.3)";
              backgroundColor = "#FFA07A";
              break;

            case 4:
            //   boxShadow = "inset 0px 0px 10px 0px rgba(0, 0, 0, 0.2)";
              backgroundColor = "#FFC0CB";
              break;

            default:
              boxShadow = `0 4px 6px -1px rgba(0, 0, 0, ${
                index / 10
              }), 0 2px 4px -1px rgba(0, 0, 0, ${index / 10})`;
              backgroundColor = "#FF333";
              break;
          }

          return (
            <div key={customer.id} className=" md:mx-10" data-aos="fade-up">
              <div>
                <p className=" text-xl font-bold text-center mr-12 py-4">
                  {customer.name}
                </p>
                <div
                  className="w-[23rem] h-[30rem] border  border-black rounded-2xl flex flex-col justify-center items-center space-x-4 "
                  style={{ boxShadow, backgroundColor }}>
                  <img
                    src={customer.img}
                    alt="customers"
                    className="w-28 h-28 rounded-full mb-7 mx-4"
                  />
                  <div className="w-full max-w-[20rem] mx-auto">
                    <p className="text-start leading-7 ">
                      {customer.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Testimonials;
