import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import { WhatsAppWidget } from "react-whatsapp-widget";
import { BsWhatsapp } from "react-icons/bs";
import Footer from "./Footer";

const HomeLayout = () => {
  return (
    <div>
      <NavBar />
      <div className="whatsapp-widget-container">
        <WhatsAppWidget CompanyIcon={BsWhatsapp} phoneNumber="2349030061167" />
      </div>
      <div className="content-container">
        <Outlet />
      </div>
      <div>
        <Footer/>
    </div>
    </div>
  );
};

export default HomeLayout;
