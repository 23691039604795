import React from "react";
import Slider from "react-slick";
import Dp from "../Assets/HomeImg.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BigShip from "../Assets/BigShip.png";
import CargoPlane from "../Assets/CargoPlane.png";
import train from "../Assets/train.png";

// Imported Components
import LandingPage from "../Components/LandingPageCard";
import TotalNumber from "../Components/TotalNumber";
import Why from "../Components/Why";
import ChooseUs from "../Components/ChooseUs";
import CustomerCard from "../Components/CustomerCard";
import WhatWeShip from "./WhatWeShip";
import Download from "./Download";
import GetQuote from "./GetQuote";
import "../../../App.css";
import Services from "./Services";
import Testimonials from "./Testimonials";
// import Footer from "../Components/Footer";

const Home = () => {
  const backgroundImages = [
    {
      image: CargoPlane,
      text: "Come experience Logistics in a way you never have  We help ease the stress that comes with logistics and make you smile!",
    },
    {
      image: train,
      text: "Your Partner in Progress. Trust us to navigate the complexities of logistics, delivering success through reliability and innovation.",
    },
    {
      image: BigShip,
      text: "We are more than logistics; we're the bridge to your aspirations. Join us in shaping a future of effortless global trade and unparalleled connectivity",
    },
  ];

  const slickSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="overflow-hidden bg-zinc-100 md:grid-col-2">
      {/* Add your existing style tags here */}

      <div className="relative flex flex-col lg:flex-row lg:h-[500px] justify-around items-center my-3 lg:mt-16 home">
        <div
          className="lg:mr-6 space-y-3 text-center lg:text-left "
          data-aos="fade-left">
          <p
            className="text-4xl lg:text-7xl font-extrabold text"
            data-aos="fade-up">
            SHIP FROM <br /> NIGERIA TO OVER <br />
            300 COUNTRIES
          </p>
          <p className="text-md lg:text-2xl order-1 lg:order-2 ">
            Enjoy seamless shipping from Nigeria to China, <br /> USA and have
            it delivered to you anywhere
          </p>
        </div>
        <div className=" md:block" data-aos="fade-right">
          <img
            src={Dp}
            alt="Display"
            className="w-full lg:w-[29rem] h-[25rem] rounded-2xl"
          />
        </div>
      </div>

      {/* At medium screen size, I want the image to go below the text, I also want the text to be smaller so that it goes below the navbar */}

      {/* <Slider {...slickSettings}>
          {backgroundImages.map(({ image, text }, index) => (
            <div key={index}>
              <section
                className="relative h-[800px]  flex flex-col justify-center items-center md:items-start"
                // style={{ backgroundImage: `url(${image})` }}
              >
                <img
                  src={image}
                  alt="images"
                  className="absolute -z-10 h-full object-cover w-full"
                />
                <div
                  className="p-6 bg-white rounded-md shadow-sm gap-3 max-w-md m-6 bg-opacity-90 border-2"
                >
                  <h2 className="text-xl font-extrabold text-[#049805] ">
                    Welcome to{" "}
                    <span className="text-red-600 text-3xl block">
                      MyAfrimall Logistics
                    </span>
                  </h2>
                  <p className="lg:text-lg sm:text-base md:text-sm font-oleo my-5">
                    {text}
                  </p>
                </div>
              </section>
            </div>
          ))}
        </Slider> */}

      <div>
        <WhatWeShip />{" "}
      </div>
      <div>
        <Download />
      </div>
      <div>
        {" "}
        <GetQuote />
      </div>
      <div>
        {" "}
        <Services />
      </div>
      <div>
        {" "}
        <Testimonials />
      </div>
      <div id="about-us">{/* <LandingPage /> */}</div>
      {/* <div>
        <TotalNumber />
      </div> */}
      <div>{/* <Why /> */}</div>
      <div>{/* <ChooseUs /> */}</div>
      <div>{/* <CustomerCard /> */}</div>
      {/* <div id="contact">
        <Footer />
      </div> */}
    </div>
  );
};

export default Home;
