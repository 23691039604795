import React from "react";
import "../../../App.css"

const GetQuote = () => {
  return (
    <div
      className="lg:h-[30rem] md:h-[35rem] w-full bg-green-400 flex flex-col"
      data-aos="fade-up">
      <p className="uppercase text-center font-bold text-5xl text-white py-12 font-sans">
        Get Quote
      </p>
      <form className="flex flex-col items-center space-y-6 text-white font-semibold text-2xl sm:flex sm:flex-col sm:items-center">
        <div className="flex flex-col md:flex-row md:space-x-8">
          <span className="flex flex-col">
            <label htmlFor="from" className="uppercase">
              From
            </label>
            <input
              id="from"
              name="from"
              type="text"
              className="w-full md:w-[35rem] h-12 border-zinc-200 border-2 bg-transparent outline-none px-3"
            />
          </span>
          <span className="flex flex-col">
            <label htmlFor="to" className="uppercase">
              To
            </label>
            <input
              id="to"
              name="from"
              type="text"
              className="w-full md:w-[35rem] h-12 border-zinc-200 border-2 bg-transparent outline-none px-3"
            />
          </span>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-8">
          <span className="flex flex-col">
            <label htmlFor="weight" className="uppercase">
              Enter Weight
            </label>
            <input
              id="weight"
              name="from"
              type="text"
              className="w-full md:w-[35rem] h-12 border-zinc-200 border-2 bg-transparent outline-none text-white px-3"
            />
          </span>
          <span className="flex flex-col">
            <label htmlFor="dropdown" className="uppercase">
              Choose Delivery Option
            </label>
            <select
              id="dropdown"
              name="dropdown"
              className="w-full md:w-[35rem] h-12 border-zinc-200 border-2 bg-transparent outline-none text-white appearance-none px-3 text-xl">
              <option value="option1" className="text-gray-800 text-xl">
                Express
              </option>
              <option value="option2" className="text-gray-800 text-xl">
                Budget
              </option>
              <option value="option3" className="text-gray-800 text-xl">
                Air Cargo
              </option>
              <option value="option4" className="text-gray-800 text-xl">
                Sea Cargo
              </option>
            </select>
          </span>
        </div>
        <div className="grid place-items-center">
          <button className="w-full md:w-[35rem] h-14 bg-white my-6 text-green-700 uppercase font-semibold hover:bg-green-400 hover:border-2 hover:text-white transition ease-in-out duration-3000 ">
            Generate Price
          </button>
        </div>
      </form>
    </div>
  );
};

export default GetQuote;
