import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import MyAfrimalllogo from "../Assets/MyAfrimalllogo.png";
import Sidebar from "./sidebar";

const NavBar = () => {
  const navLinks = [
    {
      id: 0,
      name: "Home",
      url: "/",
    },
    {
      id: 1,
      name: "About Us",
      url: "#about-us",
    },
    {
      id: 2,
      name: "Track",
      url: "https://track.myafrimall.com.ng",
    },
    {
      id: 5,
      name: "Contact Us",
      url: "/contact",
    },
    {
      id: 3,
      name: "Become an Affiliate",
      url: "https://affiliate.myafrimall.com.ng/",
    },
    // {
    //   id: 4,
    //   name: "Apply Coupon Code",
    //   url: "#coupon",
    // },
  ];

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNavShown, setIsNavShown] = useState(false);
  const [active, setActive] = useState("Home");

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const scroll = () => {
      const windowHeight = 800;
      if (window.pageYOffset > windowHeight) {
        setIsNavShown(true);
      } else {
        setIsNavShown(false);
      }
    };

    window.addEventListener("scroll", scroll);

    return () => {
      // Cleanup function
      window.removeEventListener("scroll", scroll);
    };
  }, []);

  return (
    <nav
      className={`${
        isNavShown ? "bg-white fixed" : "absolute top-0 right-0"
      } w-full p-3 py-5 z-10 transition`}>
      <div className="flex justify-between items-center gap-4 container mx-auto">
        <img
          className="w-[8rem] mt-5 mx-6"
          src={MyAfrimalllogo}
          alt="MyAfrimall Logo"
        />

        <ul className="md:flex items-center gap-5 hidden mr-10 ">
          {navLinks.map((link, ind) => (
            <li
              className={`${active === link.name ? "" : ""}`}
              key={ind}
              onClick={() => setActive(link.name)}>
              <a
                href={link.url}
                className={`text-black text-sm font-medium  ${
                  active === link.name ? "active-link" : "nav-link-hover"
                }`}
                target={
                  link.name === "Track" || link.name === "Become an Affiliate"
                    ? "_blank"
                    : undefined
                }
                rel={
                  link.name === "Track" || link.name === "Become an Affiliate"
                    ? "noopener noreferrer"
                    : undefined
                }>
                {link.name}
              </a>
            </li>
          ))}
        </ul>

        <FaBars
          className="text-xl text-pry md:hidden cursor-pointer text-green-500"
          onClick={openSidebar}
        />
      </div>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        closeSidebar={closeSidebar}
        navItems={navLinks}
      />
    </nav>
  );
};

export default NavBar;
